import { plPL } from '@mui/x-date-pickers/locales';

import { APP_URL } from "../config";

export const LANGUAGE = navigator.language || navigator.userLanguage;
export const LANGUAGE_CODE = LANGUAGE.split('-')[0];
export const DATEPICKER_LOCALES = {
  pl: plPL,
};

export const DOWNLOAD_URL = `${APP_URL}/download`;

export const RANGE_CALENDAR_DATE_FORMAT = 'yyyy-MM-dd';

export const PREMIUM_DISABLED = false;
export const PERIODS = [1, 3, 6, 12];
export const CURRENCIES = ['PLN', 'EUR', 'USD'];
export const CURRENCIES_MAP = {
  'PLN': 'zł',
  'EUR': '€',
  'USD': '$',
};
export const BASE_PRICES_PER_MONTH = {
  'PLN': 10,
  'EUR': 2.5,
  'USD': 2.6,
};
export const REDUCED_PRICES_PER_MONTH = {
  1: {
    'PLN': 10,
    'EUR': 2.5,
    'USD': 2.6,
  },
  3: {
    'PLN': 9,
    'EUR': 2.1,
    'USD': 2.3,
  },
  6: {
    'PLN': 8,
    'EUR': 1.9,
    'USD': 2.1,
  },
  12: {
    'PLN': 7,
    'EUR': 1.6,
    'USD': 1.8,
  },
};