import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import CenteredBox from './CenteredBox.tsx';
import CloseButton from './CloseButton.tsx';
import GoldButton from './GoldButton.tsx';
import PaperComponent from '../PaperComponent';

import { setPremiumConfirmDialogOpen } from '../../features/app/dialogsSlice';

import {
  CURRENCIES_MAP,
  PREMIUM_DISABLED,
  REDUCED_PRICES_PER_MONTH,
} from '../../consts/app';
import {
  borderedDialogPaperProps,
  white,
} from '../../theme';
import { roundToOneDecimal } from '../../utils/utils';

// @ts-ignore
import { ReactComponent as RoundMePlusLogo } from '../../assets/R+ound.Me.svg';

const ChangePlanButton = styled(Button)`
  color: ${white};
  text-decoration: underline;
  text-transform: none;
  font-weight: 900;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`;

const StyledRoundMePlusLogo = styled(RoundMePlusLogo)`
  width: 140px;
  height: 36px;
`;

const PremiumConfirmDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.premiumConfirmDialogOpen);
  const currencyCode = useSelector((state: any) => state.premiumDialog.currencyCode);
  const period = useSelector((state: any) => state.premiumDialog.period);

  const periodsMap = {
    1: t('1 month'),
    3: t('3 months'),
    6: t('6 months'),
    12: t('1 year'),
  };

  const handleClose = () => {
    dispatch(setPremiumConfirmDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      fullWidth
      // https://github.com/mui/material-ui/issues/43106#issuecomment-2314809028
      closeAfterTransition={false}
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center">
        {t('Confirm your choice')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <CenteredBox>
          <StyledRoundMePlusLogo />
        </CenteredBox>
        <CenteredBox mt={3}>
          <Typography variant="h6">
            {periodsMap[period]} {t('for just')} <strong>{roundToOneDecimal(REDUCED_PRICES_PER_MONTH[period][currencyCode] * period)} {CURRENCIES_MAP[currencyCode]}</strong>
          </Typography>
        </CenteredBox>
        {period !== 12 && (
          <CenteredBox mt={2} px={6}>
            <Typography variant="body2" textAlign="center">
              ...{t('or choose a longer plan and save up to')} <strong>30%</strong>!
            </Typography>
          </CenteredBox>
        )}
        <CenteredBox mt={2}>
          <ChangePlanButton onClick={handleClose}>
            {t('Change my plan')}
          </ChangePlanButton>
        </CenteredBox>
        <Box mt={3} px={1}>
          <Typography variant="body2" textAlign="center" fontWeight={300}>
            {t('You will be redirected to the payment provider.')}
          </Typography>
          <br />
          <Typography variant="caption" mt={1} mb={1} lineHeight={0}>
            {t('By clicking "Confirm" you agree to the')} <Link href="/terms-of-service/">
              {t('Terms of Service').replace('_', '')}</Link> {t('and')} <Link href="/privacy-policy/">
              {t('_Privacy Policy').replace('_', '')}</Link>.
          </Typography>
        </Box>
      </DialogContent>
      <StyledDialogActions>
        <GoldButton variant="text" onClick={handleClose}>
          {t('Go back')}
        </GoldButton>
        <GoldButton
          autoFocus
          variant="contained"
          disabled={PREMIUM_DISABLED}
          onClick={() => { }}
        >
          {t('Confirm')}
        </GoldButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default PremiumConfirmDialog;