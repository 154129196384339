import React, { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { css, styled } from '@mui/material/styles';

import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import AuthContext from '../../context/AuthContext';

import { setAuthDialogOpen } from '../../features/dialogs/authDialogSlice.ts';

import {
  honeyGlow,
  yellowBlackBoxShadow,
} from '../../theme';

import { handlePremiumClick } from '../../utils/handlers';
import { closeDrawer } from '../../utils/utils';

// @ts-ignore
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';

const StyledPlusIcon = styled(PlusIcon)`
  width: 24px;
  height: 24px;
`;

const StyledListItemButton = styled(ListItemButton)`
  font-weight: medium;
`;

const StyledListItemText = styled(ListItemText)`
  text-shadow: ${yellowBlackBoxShadow};
  color: ${honeyGlow};

  .MuiTypography-root {
    font-weight: 500;
  }
`;

const PremiumListItem: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { user } = useContext(AuthContext);

  const handleClick = () => {
    if (user) {
      // // setTimeout(() => {
      // dispatch(setDrawerRightOpen(false));
      // // }, 200);
      // // setTimeout(() => {
      // dispatch(setPremiumDialogOpen(true));
      // // }, 100);
      handlePremiumClick(dispatch);
      closeDrawer(dispatch);
    } else {
      dispatch(setAuthDialogOpen(true));
    };
  };

  return (
    <ListItem key="Premium" disablePadding onClick={handleClick}>
      <StyledListItemButton>
        <ListItemIcon>
          <StyledPlusIcon />
        </ListItemIcon>
        <StyledListItemText
          primary={
            // 'R-ound.Me store'
            // 'Get R+ound.Me'
            // 'Store'
            t('Plus Account')
            // 'Zostań PLUS'
            // 'Zostań „Plusem”'
          }
        />
      </StyledListItemButton>
    </ListItem>

  );
};

export default PremiumListItem;