import React, { useContext, useEffect, useLayoutEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import i18next from 'i18next';

import 'dayjs/locale/en-gb';
import 'dayjs/locale/pl';
import dayjs from 'dayjs';
import updateLocale from 'dayjs/plugin/updateLocale';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { dayjsCustomEnGbLocale } from '../../dayjsCustomEnGbLocale';

import NoSleep from '@zakj/no-sleep';

import AlertsSnackbar from './AlertsSnackbar';
import AuthDialog from '../auth/AuthDialog.tsx';
import BannedPinDialog from '../events/BannedPinDialog.tsx';
import BannedUserDialog from '../user/BannedUserDialog.tsx';
import BottomBar from './BottomBar.tsx';
import ConfigurationStepper from './ConfigurationStepper.tsx';
import ConfirmCancelEventDialog from '../events/ConfirmCancelEventDialog.tsx';
import ConfirmClearNotificationsDialog from './ConfirmClearNotificationsDialog.tsx';
import ConfirmDeleteAccountDialog from '../user/ConfirmDeleteAccountDialog.tsx';
import ConfirmDeleteEventDialog from '../events/ConfirmDeleteEventDialog';
import ConfirmEndEventDialog from '../events/ConfirmEndEventDialog';
import ConfirmRemoveEventFromSavedDialog from '../events/ConfirmRemoveEventFromSavedDialog';
import ConfirmRemoveFriendDialog from '../user/ConfirmRemoveFriendDialog';
import ConnectionLostDialog from './ConnectionLostDialog';
import EditEventDialog from '../events/EditEventDialog';
import EventDialog from '../events/EventDialog';
import ImageDialog from '../ImageDialog';
import IntroStepper from './IntroStepper.tsx';
import InviteFriendDialog from '../events/InviteFriendDialog.tsx';
import InviteFriendsDialog from '../events/InviteFriendsDialog';
import PremiumConfirmDialog from './PremiumConfirmDialog.tsx';
import PremiumDialog from './PremiumDialog.tsx';
import ReportDialog from './ReportDialog.tsx';
import QrCodeDialog from './QrCodeDialog.tsx';
import QrCodeScannerDialog from './QrCodeScannerDialog.tsx';
import TopBar from './TopBar.tsx';
import TrialConfirmDialog from './TrialConfirmDialog.tsx';
import UserDialog from '../user/UserDialog.tsx';

import {
  setFabSize,
  setInstalled,
  setWidth,
} from '../../features/app/appSlice';
import { setAnyDialogOpen } from '../../features/app/dialogsSlice';
import { setNotificationsAllowed } from '../../features/app/notificationsSlice';
import { setGeolocationPermissionStatus } from '../../features/map/mapSlice';

import { PREMIUM_DISABLED } from '../../consts/app';
import {
  CHECK_GEOLOCATION_PERMISSION_INTERVAL,
  CHECK_NOTIFICATIONS_PERMISSION_INTERVAL,
} from '../../consts/intervals';
import { VISIBILITY_CHANGE_REFETCH_TIMEOUT } from '../../consts/timeouts';

import AuthContext from '../../context/AuthContext';
import IntervalContext from '../../context/IntervalContext';
import {
  getFriendsAndFriendRequests,
  getInvitations,
  getNewNotifications,
} from '../../utils/getters';

import api from '../../utils/api.ts';
import { getFabSize } from '../../utils/utils';
import { setPremiumDialogOpen } from '../../features/dialogs/premiumDialogSlice.ts';

interface LayoutProps {
  bottomBar?: boolean;
  children: React.ReactNode;
};

const Layout: React.FC<LayoutProps> = ({ bottomBar = false, children }) => {
  const dispatch = useDispatch();

  const { addInterval } = useContext(IntervalContext);
  const { user } = useContext(AuthContext);

  const language = useSelector((state: any) => state.settings.language);

  const noSleepRef = useRef(new NoSleep());
  const keepScreenOn = useSelector((state: any) => state.settings.keepScreenOn);

  const lastCallTimeRef = useRef(dayjs());

  const dialogsState = useSelector((state: any) => state.dialogs);

  const handleResize = () => {
    dispatch(setFabSize(getFabSize(window.innerWidth)));
    dispatch(setWidth(window.innerWidth));
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });

  useEffect(() => {
    var intervalId: ReturnType<typeof setInterval>;
    if ('Notification' in window) {
      const checkNotificationsPermission = () => {
        if (Notification.permission === 'granted') {
          dispatch(setNotificationsAllowed(true));
        } else if (Notification.permission === 'denied') {
          dispatch(setNotificationsAllowed(false));
        };
      };

      checkNotificationsPermission();
      intervalId = addInterval(checkNotificationsPermission, CHECK_NOTIFICATIONS_PERMISSION_INTERVAL);
    };

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    i18next.changeLanguage(language);
    localStorage.setItem('language', language);
    dayjs.locale(language === 'en' ? 'en-gb' : language);
  }, [language]);

  useEffect(() => {
    const handleInteraction = () => {
      if (keepScreenOn) noSleepRef.current.enable();
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
    };

    if (keepScreenOn) {
      document.addEventListener('touchstart', handleInteraction);
      document.addEventListener('click', handleInteraction);

      /* does not work on Safari / Firefox and potentailly somewhere else */
      if (navigator.wakeLock) {
        var _event = isMobile ? new Event('touchstart', { bubbles: true }) : new Event('click', { bubbles: true });
        document.dispatchEvent(_event);
      };
    } else {
      noSleepRef.current.disable();
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
    };

    return () => {
      document.removeEventListener('click', handleInteraction);
      document.removeEventListener('touchstart', handleInteraction);
    };
  }, [keepScreenOn]);

  useEffect(() => {
    dayjs.extend(localizedFormat);
    dayjs.extend(updateLocale);
    dayjs.updateLocale('en-gb', dayjsCustomEnGbLocale);
  }, []);

  useEffect(() => {
    // related to redux-persist
    dispatch(setPremiumDialogOpen(false));
  }, []);

  useEffect(() => {
    const hasTrueValue = (obj: any) => {
      for (let key in obj) {
        if ([
          'anyDialogOpen',
          'bannedPinDialogOpen',
          'bannedUserDialogOpen',
          'companyAccountInfoDialogOpen',
          'confirmClearNotificationsDialogOpen',
          'geolocationDeniedDialogOpen',
          'inviteFriendsDialogOpen',
          'locationPermissionDialogOpen',
          'networkDialogTab',
          'premiumConfirmDialogOpen',
          'qrCodeDialogOpen',
          'qrCodeScannerDialogOpen',
          'searchFriendsDialogOpen',
          'trialConfirmDialogOpen',
          'webGLDialogOpen',
        ].includes(key)) {
          continue;
        };
        if (obj[key] === true) {
          return true;
        };
      };
      return false;
    };
    dispatch(setAnyDialogOpen(hasTrueValue(dialogsState)));
  }, [dialogsState]);

  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      const currentTime = dayjs();

      if (currentTime.diff(lastCallTimeRef.current) >= VISIBILITY_CHANGE_REFETCH_TIMEOUT) {
        if (user) {
          getNewNotifications(dispatch);
          getFriendsAndFriendRequests(dispatch);
          getInvitations(dispatch);
          if (bottomBar) {
            // TODO:
            // getEvents();
            // getUsers();
            // getUserEvents();
            // getSavedEvents();
          };
        } else {
          if (bottomBar) {
            // TODO:
            // getEvents();
          };
        };
        lastCallTimeRef.current = currentTime;
      };
    };
  };

  useLayoutEffect(() => {
    document.addEventListener('visibilitychange', onVisibilityChange);

    return () => document.removeEventListener('visibilitychange', onVisibilityChange);
  }, [user]);

  useEffect(() => {
    const handleAppInstalled = async () => {
      dispatch(setInstalled(true));
      localStorage.setItem('appInstalled', 'true');
      await api.post('/stats/', { 'action': 'appDownload' });
    };

    window.addEventListener('appinstalled', handleAppInstalled);
    return () => {
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  useEffect(() => {
    var intervalId;
    const queryPermissionStatus = async () => {
      const status = await navigator.permissions.query({ name: 'geolocation' });
      dispatch(setGeolocationPermissionStatus(status.state));
    };

    if (navigator.permissions) {
      queryPermissionStatus();
      intervalId = addInterval(queryPermissionStatus, CHECK_GEOLOCATION_PERMISSION_INTERVAL);
    };

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <>
      <AlertsSnackbar />
      <IntroStepper />
      <ConfigurationStepper />
      <TopBar bottomBar={bottomBar} />
      {children}
      {bottomBar && <BottomBar />}
      <AuthDialog />
      <ConnectionLostDialog />
      <EventDialog />
      <ImageDialog />
      {!PREMIUM_DISABLED && (
        <>
          <PremiumConfirmDialog />
          <TrialConfirmDialog />
        </>
      )}
      <PremiumDialog />
      {user ? (
        <>
          <BannedPinDialog />
          <ConfirmCancelEventDialog />
          <ConfirmClearNotificationsDialog />
          <ConfirmDeleteAccountDialog />
          <ConfirmDeleteEventDialog />
          <ConfirmEndEventDialog />
          <ConfirmRemoveEventFromSavedDialog />
          <ConfirmRemoveFriendDialog />
          <EditEventDialog />
          <InviteFriendDialog />
          <InviteFriendsDialog />
          <QrCodeDialog />
          {isMobile && (
            <QrCodeScannerDialog />
          )}
          <ReportDialog />
          <UserDialog />
        </>
      ) : (
        <BannedUserDialog />
      )}
    </>
  );
};

export default Layout;