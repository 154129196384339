import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import CenteredBox from './CenteredBox.tsx';
import CloseButton from './CloseButton.tsx';
import GoldButton from './GoldButton.tsx';
import PaperComponent from '../PaperComponent';

import { setTrialConfirmDialogOpen } from '../../features/app/dialogsSlice';

import { PREMIUM_DISABLED } from '../../consts/app';
import {
  borderedDialogPaperProps,
  white,
} from '../../theme';

// @ts-ignore
import { ReactComponent as RoundMePlusLogo } from '../../assets/R+ound.Me.svg';

const ChangePlanButton = styled(Button)`
  color: ${white};
  text-decoration: underline;
  text-transform: none;
  font-weight: 900;
`;

const StyledDialogActions = styled(DialogActions)`
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1rem;
`;

const StyledRoundMePlusLogo = styled(RoundMePlusLogo)`
  width: 140px;
  height: 36px;
`;

const TrialConfirmDialog: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const open = useSelector((state: any) => state.dialogs.trialConfirmDialogOpen);

  const handleClose = () => {
    dispatch(setTrialConfirmDialogOpen(false));
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperComponent={PaperComponent}
      hideBackdrop={false}
      // fullWidth
      // https://github.com/mui/material-ui/issues/43106#issuecomment-2314809028
      closeAfterTransition={false}
      {...borderedDialogPaperProps}
    >
      <DialogTitle textAlign="center">
        {t('Confirm trial')}
      </DialogTitle>
      <CloseButton onClick={handleClose} />
      <DialogContent>
        <CenteredBox>
          <StyledRoundMePlusLogo />
        </CenteredBox>
        <CenteredBox mt={3}>
          <Typography variant="h6">
            {t('7 days for free')}
          </Typography>
        </CenteredBox>
        <CenteredBox mt={2} px={6}>
          <Typography variant="body2" textAlign="center">
            ...{t('or choose a longer paid plan')}!
          </Typography>
        </CenteredBox>
        <CenteredBox mt={1}>
          <ChangePlanButton onClick={handleClose}>
            {t('Change my plan')}
          </ChangePlanButton>
        </CenteredBox>
        <CenteredBox mt={2} px={1}>
          <Typography variant="caption" textAlign="center">
            {t('You can use free trial only once.')}
          </Typography>
        </CenteredBox>
      </DialogContent>
      <StyledDialogActions>
        <GoldButton variant="text" onClick={handleClose}>
          {t('Go back')}
        </GoldButton>
        <GoldButton
          autoFocus
          variant="contained"
          disabled={PREMIUM_DISABLED}
          onClick={() => { }}
        >
          {t('Try out')}
        </GoldButton>
      </StyledDialogActions>
    </Dialog>
  );
};

export default TrialConfirmDialog;